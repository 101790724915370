/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';

import $ from 'jquery';
import bootstrap from "bootstrap";

global.$ = global.jQuery = $;

import navigation from "./navigation";
global.navigation = navigation;

import datevTransfer from "./datevTransfer";
global.datevTransfer = datevTransfer;