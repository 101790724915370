let datevTransfer = {
    readLogs: function (input, ta) {
        var filesReading = 0;
        console.log(input, ta);
        for (var i = 0; i < input.files.length; i++) {
            let file = input.files[i];
            let reader = new FileReader();
            reader.onload = () => {
                $(ta).val($(ta).val() + "\n" + file.name + "\n" + reader.result + "\n\n\n");
            };
            reader.readAsText(file);
        }
    },
    parseLogs(text, table) {
        let deals = [];
        let re = RegExp(/Quelldatei:.*?(\d+).xml\nAkte: 0*(\d+-\d+)\/\d*:\d* (.*)\n(\*(?:.|\n.)*|\n)/g);
        let result = [];
        while (result = re.exec(text)) {
            deals.push({
                id: result[1],
                az: result[2],
                name: result[3],
                msg: result[4].trim(),
            });
            $(table).append(`<tr><td>${result[1]}</td><td>${result[2]}</td><td>${result[3]}</td><td>${result[4].trim()}</td></tr>`);
        }
        datevTransfer.logData = deals;
    },
    sendParsedData: function () {
        $.post('/datevExport/updateAZ', {logData: datevTransfer.logData}, function(result){
            if (result.success){
                alert("Aktenzeichen wurden eingetragen.");
            }else{
                alert("Es ist ein Fehler aufgetreten.");
            }
        })
    },
    logData: [],
};

export default datevTransfer;