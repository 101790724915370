let navigation = {
    selectPortal: function(portalId){
        jQuery.post('/navigation/selectPortal', {portalId}, navigation.selectPortal_success);
    },
    selectPortal_success: function(data){
        console.log({data});
        if(data.success){
            window.location.reload(true);
        }else{
            alert("Das Portal konnte nicht gewählt werden");
        }
    }

}

export default navigation;